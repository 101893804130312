.titleInput{
    font-size: 28px;
    border:0;
    outline:0;
    color: #00565B;
    font-weight: 600;
}
.buttonRight{
    font-size: 18px;
    font-weight: 600;
    width: 102px;
    padding:8px;
    border-radius: 20px;
    border:1px solid #00565B;
    color: #ffffff;
    background: #00565B;
    cursor: pointer;
    transition-duration: 0.3s;
}
.buttonRight:hover{
    font-size: 18px;
    width: 102px;
    padding:8px;
    border-radius: 20px;
    border:1px solid #013f42;
    color: #ffffff;
    background: #013f42;
    cursor: pointer;
    box-shadow: 0 0 0 1px transparent, 0 0 0 2px #013f42;
}