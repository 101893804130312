.rightactions{
    border:0;
    outline: none;
    font-family: Ubuntu;
    background:transparent;
    transition-duration: 0.5s;
}
.rightactions button,.rightactions button a {
    font-size:28px;
    font-weight: 800;
    padding:16px;
    display:grid;
    place-content: center;
    color:"#00565B";
    cursor:pointer;
    user-select:none;
    border:0;
    font-family: Ubuntu;
    background:transparent;
}
button a{
    text-decoration: none;
    color: #00565B;
}
.rightactions > button:hover a{
    text-decoration: underline;
}